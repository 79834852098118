(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:MultiWalletCardItemDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('MultiWalletCardItemDialogCtrl', MultiWalletCardItemDialogCtrl);

  function MultiWalletCardItemDialogCtrl($mdDialog, $mdToast, FormControl, CardType, cardTypeMap, _, $rootScope, DataReconciliation, $q) {
    var vm = this
      , formControl = new FormControl()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , deferred = $q.defer();

    vm.itemDialogForm = {};
    vm.isNewItem = _.isEmpty(vm.item);
    vm.selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

    vm.item.dateOfBirth = !vm.isNewItem ? new Date(vm.item.dateOfBirth) : null;

    CardType.query().$promise
      .then(function (data) {
        vm.cardTypeId = _.result(_.find(data, { 'category': cardTypeMap[vm.selectedProductType] }), 'id');
      });

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    function createID() {
      var id = vm.size + 1;
      var idAlreadyExists = _.find(vm.items, { id: id });

      if (idAlreadyExists) {
        id++;
      }

      return id;
    }

    vm.hasDuplicateEmail = function hasDuplicateEmail() {
      return _.find(vm.items, { email: vm.item.email });
    };

    vm.submit = function submit() {
      if (vm.hasDuplicateEmail() && vm.isNewItem) {
        $mdToast.showToast('form.validation.multiwalletDuplicateEmail');
        return;
      }

      formControl.process(deferred.promise);

      return DataReconciliation.validateDataReconciliations(vm.item).$promise
        .then(function () {
          if (vm.isNewItem) {
            vm.item.id = createID();
            $rootScope.$broadcast('itemList.addNewItem', vm.item);
            $mdToast.showToast('order.unique.toast.save');
          } else {
            $rootScope.$broadcast('itemList.updateItem', vm.item);
            $mdToast.showToast('order.unique.toast.edit');
          }

          vm.closeDialog();
        })
        .catch(function (error) {
          deferred.reject(error);
        });
    };
  }
}());
